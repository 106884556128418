// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


// ------------------------ Colours //

/// Primary brand color
/// @type Color
$brand-primary-color: rgb(0, 0, 0) !default;

/// Secondary brand color
/// @type Color
$brand-secondary-color: #EFEBE8 !default;

// ------------------------ Container //

/// Container's maximum width
/// @type Length
$max-width: 1280px !default;

// ------------------------ Breaks //

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;



/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'https://cdn.example.com/_assets/';
$base-url: '/_assets/' !default;
