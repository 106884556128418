html,body {
    height: 100%;
}
main, footer {
    position: absolute;
    top: 1.67em;
    left: auto;
    right: auto;
    width: 100%;
    text-align: center;
}
footer {
    top: auto;
    bottom: 1.67em;
}