// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
    @if $self {
      &,
      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        @content;
      }
    } @else {
      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        @content;
      }
    }
  }
  
  /// Make a context based selector a little more friendly
  /// @author Kitty Giraudel
  /// @param {String} $context
  @mixin when-inside($context) {
    #{$context} & {
      @content;
    }
  }