@font-face {
    font-family: "Borna Medium";
    src: url("../fonts/borna-medium-webfont.woff2") format("woff2");
}
@font-face {
    font-family: "Borna Regular";
    src: url("../fonts/borna-regular-webfont.woff2") format("woff2");
}
@font-face {
    font-family: "Borna SemiBold";
    src: url("../fonts/borna-semibold-webfont.woff2") format("woff2");
}
@font-face {
    font-family: "Borna Bold";
    src: url("../fonts/borna-bold-webfont.woff2") format("woff2");
}
@font-face {
    font-family: "Chaney Extended";
    src: url("../fonts/CHANEY-Extended.woff2") format("woff2");
}
@font-face {
    font-family: "Chaney Regular";
    src: url("../fonts/CHANEY-Regular.woff2") format("woff2");
}
@font-face {
    font-family: "Chaney Ultra Extended";
    src: url("../fonts/CHANEY-UltraExtended.woff2") format("woff2");
}
@font-face {
    font-family: "Chaney Wide";
    src: url("../fonts/CHANEY-Wide.woff2") format("woff2");
}