.chaney-extended,
.chaney-regular,
.chaney-ultra-extended,
.chaney-wide,
.borna-medium,
.borna-regular,
.borna-semibold,
.borna-bold
p {
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    line-height: 1.2;
}
p {
    font-family: "Borna Regular", sans-serif;
    font-size: 0.75em;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.8;
}
a, small {
    font-family: "Borna Regular", sans-serif;
    font-size: 0.5em;
    text-transform: uppercase;
}
a {
    display: inline-block;
    color: #000;
    background: #fff;
    padding: 1.67em 2em;
    border-radius: 0.75em;
    text-decoration: none;
    @include on-event {
        text-decoration: none;
    }
}
.borna-medium {
    font-family: "Borna Medium", sans-serif;
}
.borna-regular {
    font-family: "Borna Regular", sans-serif;
}
.borna-semibold {
    font-family: "Borna SemiBold", sans-serif;
}
.borna-bold {
    font-family: "Borna Bold", sans-serif;
}
.chaney-extended {
    font-family: "Chaney Extended", sans-serif;
}
.chaney-regular {
    font-family: "Chaney Regular", sans-serif;
}
.chaney-ultra-extended {
    font-family: "Chaney Ultra Extended", sans-serif;
}
.chaney-wide {
    font-family: "Chaney Wide", sans-serif;
}
header {
    height: 100%;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.hero--title {
    font-size: 2.8em;
    font-size: 11vw;
    margin: 0;
    line-height: 0.75em;
}

.redacted {
    background: #000;
    padding: 0.2em 0.3em;
}

.uppercase {
    text-transform: uppercase;
}